import React from 'react';

import {
  StyledHeroSection,
  HeroHeadline,
  HeroText,
  CollatoLink,
  EELink,
  MTLink,
  TwitterLink,
  InstagramLink,
} from './styled';

const HeroSection = () => (
  <StyledHeroSection>
    <HeroText>
      <HeroHeadline>
        <p>
          I'm Sebastian. I enjoy building products. I work on 
          <CollatoLink href="https://collato.com" target="_blank">
            Collato
          </CollatoLink>
          which I started together with super talented friends.
          Previously I was CTO at
          <EELink href="https://www.everestate.com" target="_blank" rel="nofollow">
            EverEstate
          </EELink>
          and
          <MTLink href="https://www.metatrust.com" target="_blank" rel="nofollow">
            Metatrust.
          </MTLink>
        </p>
        <p>
          You can find me on 
          <TwitterLink href="https://twitter.com/sebabytes" target="_blank">
            Twitter
          </TwitterLink>
          and 
          <InstagramLink href="https://instagram.com/sebabytes" target="_blank">
            Instagram.
          </InstagramLink>
        </p>
      </HeroHeadline>
    </HeroText>
  </StyledHeroSection>
);

export default HeroSection;
