import styled from 'styled-components';

const StyledHeroSection = styled.div`
  margin-top: 20px;
  margin-bottom: 120px;
  display: flex;
  flex-direction: row;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 0 16px;
    margin-top: 32px;
    margin-bottom: 0px;
  }
`;

const HeroHeadline = styled.div`
  p, a {
    font-size: 32px;
    font-family: 'FuturaHeavy';
    line-height: 1.64;
  }
  p {
    color: rgb(80, 80, 80);
    margin-top: 46px;
  }
  a {
    text-decoration: none;
    margin: 0 10px;
  }
`;

const HeroText = styled.div`
  margin-top: 0px;
  width: 100%;
  max-width: 670px;
`;

const HeroTextBody = styled.div`
  margin-top: 16px;
  margin-bottom: 32px;
  color: #191847;
`;

const HeroImage = styled.div`
  margin-top: 0px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const CollatoLink = styled.a`
  color: #80c3bc;
  :hover {
    background-color: #80c3bc64;
  }
`;

const EELink = styled.a`
  color: #a53860;
  :hover {
    background-color: #a5386032;
  }
`;

const MTLink = styled.a`
  color: rgb(179, 144, 73);
  :hover {
    background-color: rgb(179, 144, 73, 0.32);;
  }
`;

const TwitterLink = styled.a`
  color: rgb(29, 161, 242);
  :hover {
    background-color: rgb(180, 216, 239);
  }
`;

const InstagramLink = styled.a`
  color: #e53862;
  :hover {
    background-color: #e5386232;
  }
`;


export {
  StyledHeroSection,
  HeroHeadline,
  HeroText,
  HeroTextBody,
  HeroImage,
  CollatoLink,
  EELink,
  MTLink,
  TwitterLink,
  InstagramLink,
};
