import React from 'react';
import { GatsbySeo as SEO } from 'gatsby-plugin-next-seo';

import Layout from '@components/Layout/Layout';
import HeroSection from '@components/Sections/Hero/HeroSection';

const IndexPage = () => {
  return (
    <Layout>
      <SEO
        title="Sebabytes"
        description="Sebastian is the co-founder and CTO of Collato. Before, he was CTO at EverEstate and Metatrust, two proptech startups in Berlin."
      />
      <HeroSection />
    </Layout>
  );
};

export default IndexPage;
